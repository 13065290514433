import React, { Component } from "react"
import { Link } from "gatsby"
import config from "../../data/config"
import PostCard from "../components/articles/PostCard"
import Helmet from "react-helmet"
import Footer from "../components/index/Footer"
import Header from "../components/index/Header"
import "../components/index/layout.css"
const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/blog/${props.url}`} className="button is-rounded">
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className="button is-rounded">
        {props.text}
      </span>
    )
  }
}

export default class BlogPage extends Component {
  render() {
    const { pageContext } = this.props
    const { group, index, first, last } = pageContext
    const previousUrl = index - 1 === 1 ? "" : (index - 1).toString()
    const nextUrl = (index + 1).toString() + "/"

    const websiteSchemaOrgJSONLD = {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
    }

    return (
      <div>
        <Helmet>
          <title>SimpleReport | Blog</title>
          {/* Schema.org tags */}
          <script type="application/ld+json">
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>
        <Header />
        <div className="nonHeroPageHero">
          <div className="pageTitle">
            <h1 id="pageTitle">Blog</h1>
          </div>
        </div>

        <div className="Container">
          <div className="subContainer">
            <section className="section">
              <PostCard posts={group} />
              <section className="section">
                <div className="buttons is-centered">
                  <PaginationLink
                    test={first}
                    url={previousUrl}
                    text="Previous Page"
                  />
                  <PaginationLink test={last} url={nextUrl} text="Next Page" />
                </div>
              </section>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
