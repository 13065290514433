module.exports = {
  siteTitle: "Simple Report", // Site title.
  siteTitleAlt: "Simple Report Technologies", // Alternative site title for SEO.
  siteUrl: "https://simplereport.ca", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: "SimpleReport is a B2B digital reporting system.", // Website description used for RSS feeds/meta description tag.
  googleTagManagerID: "", // GTM tracking ID.
  disqusShortname: "SimpleReport", // Disqus shortname.
  userLocation: "Vancouver BC Canada",
  userDescription: "",
  copyright: "SimpleReport 2019. All Rights Reserved.", // Copyright string for the footer of the website and RSS feed.
  backgroundColor: "#ffffff", // Used for setting manifest background color.
}
